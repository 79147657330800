import React from 'react'

enum MapStyle {
  OSM = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  WIKIMEDIA = 'https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png',
  HUMANITARIAN_OSM = 'http://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png',
  OSM_FRENCH = 'http://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png',
  MAPSURFER_ROADS = 'http://korona.geog.uni-heidelberg.de/tiles/roads/x={x}&y={y}&z={z}',
  MAPSURFER_ROADS_GRAY = 'http://korona.geog.uni-heidelberg.de/tiles/roadsg/x={x}&y={y}&z={z}',
  CARTO_LIGHT = 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
  CARTO_DARK = 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png'
}

interface ILeafletMapProps {
  defaultZoom?: number
  mapStyle?: MapStyle
  meta: ILeafletMapMetaProps
  mapMarkerAlt: {
    publicURL: string
  }
}

interface ILeafletMapMetaProps {
  title: string
  address: {
    country: string
    locality: string
    region: string
    postalCode: string
    streetAddress: string
    coordinates: {
      lat: number
      long: number
    }
  }
}

export default class LeafletMapComponent extends React.Component<ILeafletMapProps, {}> {
  private static defaultProps: Partial<ILeafletMapProps> = {
    defaultZoom: 13,
    mapStyle: MapStyle.WIKIMEDIA
  }

  map?: L.Map
  mapEl: React.RefObject<any>

  constructor(props: ILeafletMapProps) {
    super(props)

    this.mapEl = React.createRef()
  }

  componentDidMount(): void {
    this.setupMap()
  }

  render() {
    return (
      <section className="LeafletMapComponent hero is-large"
               aria-label="Mappa della nostra sede">

        <div ref={this.mapEl}
             className="hero-body"
             aria-label="Mappa della nostra sede" />

      </section>
    )
  }

  private setupMap(): void {
    // Only runs on Client, not on server render
    const { meta, mapMarkerAlt } = this.props
    const { address: { coordinates: coords } } = meta

    const L = require('leaflet')


    this.map = L.map(this.mapEl.current, {
      scrollWheelZoom: false,
    }).setView([45.63990, 12.31986], this.props.defaultZoom!)

    L.tileLayer(this.props.mapStyle as string, {
      attribution: '&copy; <a href="http://osm.org/copyright" target="_blank" rel="noopener">OpenStreetMap</a> contributors'
    }).addTo(this.map)


    const marker = L.marker([45.63990, 12.31986], {
      icon: new L.Icon({
        iconUrl: mapMarkerAlt.publicURL,
        iconSize: new L.Point(44, 64),
        iconAnchor: new L.Point(22, 32),
        className: 'hq-icon',
      })
    }).addTo(this.map)

    const popupContent = `  
        <a href="https://www.google.com/maps/search/?api=1&query=${coords.lat},${coords.long}"
          target="_blank"
          rel="noopener">
          <strong>GDTecnologie</strong>
          <address>
            ${meta.address.streetAddress}<br />
            ${meta.address.postalCode} ${meta.address.locality}
            (${meta.address.region}), ${meta.address.country}
          </address>
          <small>${coords.lat}, ${coords.long}</small>
        </a>`
    marker.bindPopup(popupContent)
  }
}

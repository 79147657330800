import classNames from 'classnames'
import React from 'react'
import posed from 'react-pose'
import { CurrentPose } from 'react-pose/lib/components/PoseElement/types'

import PrivacyContent from './PrivacyContent'

// TODO accept on modal should accept and remove GDPR banner

enum PosedModalState {
  Visible = 'visible',
  Hidden = 'hidden',
}

const ModalBackground = posed.div({
  [PosedModalState.Visible]: {
    opacity: 1,
    transition: {
      duration: 50,
      ease: 'easeOut',
    },
  },
  [PosedModalState.Hidden]: {
    opacity: 0,
    transition: {
      duration: 50,
      ease: 'easeOut',
    },
  },
})

const ModalCard = posed.div({
  [PosedModalState.Visible]: {
    y: 0,
    opacity: 1,
    delay: 250,
  },
  [PosedModalState.Hidden]: {
    y: 500,
    opacity: 0,
    transition: {
      y: { duration: 250 },
      opacity: { duration: 200 },
    },
  },
})

interface IPrivacyModalProps {
  acceptButtonText?: string,
  cancelButtonText?: string,
  show?: boolean,
  onAccept?: () => any,
  onCancel?: () => any,
}

interface IPrivacyModalState {
  isActive: boolean
}

export default class PrivacyModal extends React.Component<IPrivacyModalProps, IPrivacyModalState> {
  private static defaultProps: Partial<IPrivacyModalProps> = {
    acceptButtonText: 'Accetta',
    cancelButtonText: 'Rifiuta',
    onAccept: () => {
    },
    onCancel: () => {
    },
    show: true,
  }

  state = {
    isActive: false,
  }

  static getDerivedStateFromProps(props: IPrivacyModalProps) {
    if (props.show === true) {
      return { isActive: true }
    }
    return null
  }

  render() {
    const {
      acceptButtonText,
      cancelButtonText,
      onAccept,
      onCancel,
      show,
    } = this.props
    const { isActive } = this.state
    const poseState = show ? PosedModalState.Visible : PosedModalState.Hidden

    return (
      <div className={classNames('PrivacyModal', 'modal', { 'is-active': isActive })}
           role="dialog"
           aria-hidden={!isActive}
           aria-modal="true"
           tabIndex={isActive ? undefined : -1}>
        <ModalBackground className="modal-background"
                         role="presentation"
                         pose={poseState}
                         onClick={onCancel} />
        <ModalCard className="modal-card"
                   pose={poseState}
                   onPoseComplete={this.onCardPoseComplete}>
          <header className="modal-card-head">
            <div className="modal-card-title">Privacy & Cookie policy</div>
            <button className="delete"
                    type="button"
                    aria-label="close"
                    onClick={onCancel} />
          </header>
          <section className="modal-card-body">
            <PrivacyContent isEmbed />
          </section>
          {(acceptButtonText != null && cancelButtonText != null) && (
            <footer className="modal-card-foot">
              {cancelButtonText != null && (
                <button className="button"
                        type="button"
                        onClick={onCancel}>
                  {cancelButtonText}
                </button>
              )}
              {acceptButtonText != null && (
                <button className="button is-primary"
                        type="button"
                        onClick={onAccept}>
                  {acceptButtonText}
                </button>
              )}
            </footer>
          )}
        </ModalCard>
      </div>
    )
  }

  private onCardPoseComplete = (pose: CurrentPose) => {
    if (pose === PosedModalState.Hidden) {
      this.setState({ isActive: false })
    }
  }
}

import axios from 'axios'
import classNames from 'classnames'
import { graphql, StaticQuery, } from 'gatsby'
import querystring from 'querystring'
import React from 'react'
import Helmet from 'react-helmet'
import { FaCheck, FaExclamationTriangle, } from 'react-icons/fa'
import posed, { PoseGroup } from 'react-pose'
import Recaptcha from 'react-recaptcha'

import PrivacyModal from '../privacy/PrivacyModal'

enum FormPhase {
  PRESENT = 0,
  LOADING,
  DONE,
  ERROR
}

enum FieldId {
  NAME = 'contact-name',
  EMAIL = 'contact-email',
  MESSAGE = 'contact-message',
  PRIVACY = 'contact-privacy',
}

const SuccessBox = posed.div({
  enter: {
    y: 0,
    opacity: 1,
    transition: {
      y: {
        type: 'spring',
        stiffness: 1000,
        damping: 15,
      },
      default: { duration: 300 },
    },
  },
  exit: {
    y: 50,
    opacity: 0,
    transition: { duration: 150 },
  },
})

interface IContactFormProps {
  notiShowDuration?: number
}

interface IContactFormState {
  name: {
    value: string
    valid: boolean
    message: string
  }
  email: {
    value: string
    valid: boolean
    message: string
  }
  message: {
    value: string
    valid: boolean
    message: string
  }
  privacy: {
    value: boolean
    valid: boolean
    message: string
  }
  recaptcha: string
  showAsteriskHelp: boolean
  showPrivacyDialog: boolean
  phase: FormPhase
}

interface IContactFormRenderProps {
  site: {
    siteMetadata: {
      title: string
      recaptchaSiteKey: string
    }
  }
}

const initialFormState: Partial<IContactFormState> = {
  name: {
    value: '',
    valid: true,
    message: ''
  },
  email: {
    value: '',
    valid: true,
    message: ''
  },
  message: {
    value: '',
    valid: true,
    message: ''
  },
  privacy: {
    value: false,
    valid: true,
    message: ''
  },
  recaptcha: undefined
}

const query = graphql`query ContactFormQuery {
  site {
    siteMetadata {
      title
      recaptchaSiteKey
    }
  }
}`

export default class ContactForm extends React.Component<IContactFormProps, IContactFormState> {
  private static defaultProps: Partial<IContactFormProps> = {
    notiShowDuration: 5000,
  }

  private static FORM_NAME = 'contatti'

  // @ts-ignore
  state: IContactFormState = {
    ...initialFormState,
    showAsteriskHelp: false,
    showPrivacyDialog: false,
    phase: FormPhase.PRESENT,
  }

  private recaptchaRef: React.RefObject<Recaptcha>

  constructor(props: IContactFormProps) {
    super(props)

    this.recaptchaRef = React.createRef()
  }

  render() {
    const render = ({ site: { siteMetadata: meta } }: IContactFormRenderProps) => {
      const {
        name,
        email,
        message,
        privacy,
        recaptcha,
        showAsteriskHelp,
        showPrivacyDialog,
        phase,
      } = this.state

      // `?no-cache=1` prevents offline plugin from caching the request
      // https://github.com/gatsbyjs/gatsby/issues/7997#issuecomment-419749232
      return (
        <form name={ContactForm.FORM_NAME}
              aria-label={`Invia un messaggio a ${meta.title}`}
              className="ContactForm is-base has-overlay"
              data-netlify="true"
              data-netlify-recaptcha="true"
              data-netlify-honeypot="bot-field"
              method="POST"
              action="/contatti?no-cache=1"
              onSubmit={this.handleSubmit}>

          <Helmet>
            <script src="https://www.google.com/recaptcha/api.js?render=explicit" async defer />
          </Helmet>

          <input type="hidden" name="form-name" value={ContactForm.FORM_NAME} />
          <input hidden name="bot-field" />

          <div className="field">
            <label htmlFor={FieldId.NAME} className="label">
              Nome*
              <div className="control has-icons-right">
                <input id={FieldId.NAME}
                       type="text"
                       name="name"
                       value={name.value}
                       className={classNames('input', { 'is-danger': !name.valid })}
                       placeholder="Mario Rossi"
                       required
                       aria-required="true"
                       aria-invalid={!email.valid}
                       disabled={phase !== FormPhase.PRESENT}
                       aria-disabled={phase !== FormPhase.PRESENT}
                       onChange={this.handleInputChange}
                       onInvalid={this.handleInputInvalid} />
                {!name.valid && (
                  <span className="icon is-small is-right">
                    <FaExclamationTriangle />
                  </span>
                )}
              </div>
            </label>
            {!name.valid && <p className="help is-danger">{name.message}</p>}
          </div>

          <div className="field">
            <label htmlFor={FieldId.EMAIL} className="label">
              Email*
              <div className="control has-icons-right">
                <input id={FieldId.EMAIL}
                       type="email"
                       name="email"
                       value={email.value}
                       className={classNames('input', { 'is-danger': !email.valid })}
                       placeholder="m.rossi@example.com"
                       required
                       aria-required="true"
                       aria-invalid={!email.valid}
                       disabled={phase !== FormPhase.PRESENT}
                       aria-disabled={phase !== FormPhase.PRESENT}
                       onChange={this.handleInputChange}
                       onInvalid={this.handleInputInvalid} />
                {!email.valid && (
                  <span className="icon is-small is-right">
                    <FaExclamationTriangle />
                  </span>
                )}
              </div>
            </label>
            {!email.valid && <p className="help is-danger">{email.message}</p>}
          </div>

          <div className="field">
            <label htmlFor={FieldId.MESSAGE} className="label">
              Messaggio
              <div className="control">
                <textarea id={FieldId.MESSAGE}
                          name="message"
                          value={message.value}
                          className={classNames('textarea', { 'is-danger': !message.valid })}
                          placeholder="Buongiorno, ..."
                          required
                          aria-required="true"
                          aria-invalid={!message.valid}
                          spellCheck
                          disabled={phase !== FormPhase.PRESENT}
                          aria-disabled={phase !== FormPhase.PRESENT}
                          onChange={this.handleInputChange}
                          onInvalid={this.handleInputInvalid} />
              </div>
            </label>
            {!message.valid && <p className="help is-danger">{message.message}</p>}
          </div>

          <div className="field">
            <div className="control">
              <label htmlFor={FieldId.PRIVACY} className="checkbox">
                <input id={FieldId.PRIVACY}
                       type="checkbox"
                       name="privacy"
                       checked={privacy.value}
                       disabled={phase !== FormPhase.PRESENT}
                       aria-disabled={phase !== FormPhase.PRESENT}
                       onChange={this.handleInputChange} />
                {' '}
                Ho letto e accetto le condizioni della
                {' '}
                <a onClick={this.onShowPrivacyDialog}>
                  Privacy Policy
                </a>
                *
              </label>
            </div>
          </div>

          <div className="field">
            <div className="control">
              <Recaptcha sitekey={meta.recaptchaSiteKey}
                         ref={this.recaptchaRef}
                         render="explicit"
                         onloadCallback={this.onRecaptchaLoaded}
                         expiredCallback={this.handleRecaptchaExpired}
                         verifyCallback={this.handleRecaptcha} />
            </div>
          </div>

          <div className="field is-grouped is-grouped-right">
            {showAsteriskHelp && (
              <div className="help has-text-right">
                I campi marcati con l&apos;asterisco&nbsp;(*) sono obbligatori
              </div>
            )}

            <div className="control">
              <button type="submit"
                      className={classNames('button', 'is-primary', { 'is-loading': phase === FormPhase.LOADING })}
                      aria-label="Invia il messaggio"
                      disabled={!privacy.value || !recaptcha}>
                Invia
              </button>
            </div>
          </div>

          <div className="is-overlay">
            <PoseGroup>
              {phase === FormPhase.DONE && (
                <SuccessBox key="box" className="OverlayBox box">
                  <div className="media">
                    <div className="media-left">
                      <p className="icon is-large has-text-success">
                        <FaCheck size="100%" />
                      </p>
                    </div>
                    <div className="media-content">
                      <div className="content">
                        <p>
                          <strong>Il messaggio è stato inviato con successo!</strong>
                          <br />
                          Faremo in modo di risponderti al più presto.
                        </p>
                      </div>
                    </div>
                  </div>
                </SuccessBox>
              )}
              {phase === FormPhase.ERROR && (
                <SuccessBox key="box" className="OverlayBox box">
                  <div className="media">
                    <div className="media-left">
                      <p className="icon is-large has-text-danger">
                        <FaCheck size="100%" />
                      </p>
                    </div>
                    <div className="media-content">
                      <div className="content">
                        <p>
                          <strong>Si è verificato un errore.</strong>
                          <br />
                          Non è stato possibile inviare il messaggio, riprova più tardi.
                        </p>
                      </div>
                    </div>
                  </div>
                </SuccessBox>
              )}
            </PoseGroup>
          </div>

          <PrivacyModal show={showPrivacyDialog}
                        onAccept={this.onAcceptPrivacyDialog}
                        onCancel={this.onCancelPrivacyDialog} />

        </form>
      )
    }

    return (
      <StaticQuery query={query} render={render} />
    )
  }

  private handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { target } = event
    const { name } = target
    const value = target instanceof HTMLInputElement && target.type === 'checkbox' ?
      target.checked : target.value

    // @ts-ignore
    this.setState({
      [name]: {
        value,
        valid: true,
      },
      showAsteriskHelp: false,
    })
  }

  private handleInputInvalid = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event.preventDefault()
    const { currentTarget: target } = event
    const { name } = target

    // @ts-ignore
    this.setState({
      [name]: {
        valid: false,
        message: target.validationMessage || 'Questo campo non è valido. Controlla l\'input.',
      },
      showAsteriskHelp: true,
    })
  }

  private handleRecaptcha = (value: string) => {
    this.setState({ recaptcha: value })
  }

  private handleRecaptchaExpired = () => {
    console.debug('Recaptcha expired. Reloading...')
    this.setState({ recaptcha: '' })
    if (this.recaptchaRef.current) {
      this.recaptchaRef.current.reset()
    }
  }

  private onRecaptchaLoaded = () => {
    console.debug('Recaptcha loaded')
  }

  /**
   * Send contact request using Netlify forms
   * @see https://www.netlify.com/docs/form-handling/#ajax-form-submissions
   */
  private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const { notiShowDuration } = this.props
    const { name, email, message, privacy, phase, recaptcha } = this.state

    if (phase !== FormPhase.PRESENT) {
      return
    }

    this.setState({ phase: FormPhase.LOADING })

    new Promise((resolve, reject) => {
      if (!event.currentTarget.action) {
        return reject('Form action not found')
      }

      if (!recaptcha) {
        return reject('Invalid recaptcha')
      }

      const request = axios.post(
        event.currentTarget.action,
        querystring.stringify({
          name: name.value,
          email: email.value,
          message: message.value,
          privacy: privacy.value,
          'form-name': ContactForm.FORM_NAME,
          'g-recaptcha-response': recaptcha,
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          }
        }
      )

      return resolve(request)
    })
      .then(() => {
        // @ts-ignore
        this.setState({ phase: FormPhase.DONE, ...initialFormState, })
        if (this.recaptchaRef.current) {
          this.recaptchaRef.current.reset()
        }
      })
      .catch((error) => {
        console.error(error)
        this.setState({ phase: FormPhase.ERROR })
      })
      .then(() => {
        // Hide result popup
        setTimeout(() => this.setState({ phase: FormPhase.PRESENT }), notiShowDuration)
      })
  }

  private onShowPrivacyDialog = () => {
    this.setState({ showPrivacyDialog: true })
  }

  private onAcceptPrivacyDialog = () => {
    this.setState({ showPrivacyDialog: false })
    const checkbox = document.getElementById(FieldId.PRIVACY)
    if (checkbox instanceof HTMLInputElement) {
      checkbox.checked = true
    }
  }

  private onCancelPrivacyDialog = () => {
    this.setState({ showPrivacyDialog: false })
  }
}

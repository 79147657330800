import { graphql, } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'

import ContactForm from '../components/contatti/ContactForm'
import LeafletMapComponent from '../components/contatti/LeafletMapComponent'
import Layout from '../components/layout'
import Address from '../components/meta/Address'

interface IContattiPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string
        subtitle: string
        by: string
        piva: string
        address: {
          country: string
          locality: string
          region: string
          postalCode: string
          streetAddress: string
          coordinates: {
            lat: number
            long: number
          }
        }
        contatti: {
          tel: string
          email: string
          pec: string
        }
      }
    }
    mapMarkerAlt: {
      publicURL: string
    }
  }
}

export const query = graphql`query ContattiPageQuery {
  site {
    siteMetadata {
      title
      subtitle
      by
      address {
        country
        locality
        region
        postalCode
        streetAddress
        coordinates {
          lat
          long
        }
      }
      piva
      contatti {
        tel
        email
        pec
      }
    }
  }
  mapMarkerAlt: file(relativePath:{ eq: "map-marker-alt-solid.svg"}) {
    publicURL
  }
}`

export default class ContattiPage extends React.Component<IContattiPageProps, {}> {
  private comeRaggiungerci = 'Ci trovi a 5 minuti dal\'uscita Treviso Sud dell\'autostrada A4 e a 15 minuti dal centro di Treviso.'

  render() {
    const { data: { site: { siteMetadata: meta }, mapMarkerAlt } } = this.props
    const { address: { coordinates: coords } } = meta
    const telLinkHref = meta.contatti.tel.replace(/\s+/g, '')
      .replace(/[()]/g, '')

    return (
      <Layout meta={{description: this.comeRaggiungerci}}>
        <Helmet>
          <title>Contatti | {meta.title} di {meta.by}</title>
        </Helmet>

        <h1 className="is-sr-only">Contattaci</h1>

        <LeafletMapComponent meta={meta} mapMarkerAlt={mapMarkerAlt} />

        <div className="Contatti section container is-fluid">
          <div className="columns is-variable is-8">
            <section className="column is-half content">
              <p className="title is-3">{meta.title}</p>
              <p className="subtitle">di {meta.by}</p>

              <Address aria-label="Il nostro indirizzo" />

              <p>
                <span>
                  Telefono:&nbsp;
                  <a href={`tel:${telLinkHref}`}
                     aria-label={`Chiama ${meta.title}`}>
                    {meta.contatti.tel}
                  </a>
                </span>
                <br />
                <span>
                  e-mail:&nbsp;
                  <a href={`mailto:${meta.contatti.email}`}
                     aria-label={`Invia una email a ${meta.title}`}>
                    {meta.contatti.email}
                  </a>
                </span>
                <br />
                <span>
                  <span aria-label="Posta Elettronica Certificata">PEC</span>:&nbsp;
                  <a href={`mailto:${meta.contatti.pec}`}>
                    {meta.contatti.pec}
                  </a>
                </span>
              </p>

              <h2 className="title is-4">Orari</h2>
              <table className="timetable table is-narrow is-hoverable">
                <thead>
                  <tr>
                    <th>Giorni</th>
                    <th>Mattina</th>
                    <th>Pomeriggio</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Lunedì &mdash; Venerdì</td>
                    <td>8:30 &mdash; 12:30</td>
                    <td>14:00 &mdash; 16:00</td>
                  </tr>
                  <tr>
                    <td>Sabato</td>
                    <td colSpan={2}>Chiuso</td>
                  </tr>
                </tbody>
              </table>

              <h2 className="title is-4">Come raggiungerci</h2>
              <p>
                {this.comeRaggiungerci}
              </p>
              <p>
                <a href={`https://www.google.com/maps/dir/?api=1&destination=${coords.lat},${coords.long}`}
                   title="Mostrami la strada con Google Maps"
                   target="_blank"
                   rel="noopener">
                  Mostrami la strada
                </a>
              </p>

            </section>

            <section className="column is-half content">
              <h2 className="title is-4">Scrivici!</h2>
              <ContactForm />
            </section>
          </div>
        </div>
      </Layout>
    )
  }
}
